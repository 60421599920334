import { css } from '@emotion/css';

/**
 * Styles
 */
export const Styles = () => ({
  wrapper: css`
    position: relative;
  `,
});
